import React, { useEffect } from 'react';
import bgImage from '../assets/img/bg.jpg';
import layerImage from '../assets/img/layer.png';
import maskImage from '../assets/img/mask.png';
import muteImage from '../assets/img/mute-volume.svg';
import lowImage from '../assets/img/low-volume.svg';
import midImage from '../assets/img/mid-volume.svg';
import highImage from '../assets/img/high-volume.svg';
import pauseImage from '../assets/img/pause.svg';
import playImage from '../assets/img/play.svg';
import gearImage from '../assets/img/gear.svg';
import infoImage from '../assets/img/info-circle.svg';

const imagePaths = [
  bgImage,
  layerImage,
  maskImage,
  muteImage,
  lowImage,
  midImage,
  highImage,
  pauseImage,
  playImage,
  gearImage,
  infoImage
];

const PreloadImages = ({ onLoadingComplete }) => {
  useEffect(() => {
    const loadImage = (path) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = path;
        img.onload = () => resolve();
        img.onerror = (error) => reject(error);
      });
    };

    const preloadImages = async () => {
      try {
        const promises = imagePaths.map((path) => loadImage(path));
        await Promise.all(promises);
        if (onLoadingComplete) {
          onLoadingComplete(); // 画像の読み込みが完了したらコールバックを実行
        }
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    preloadImages();
  }, [onLoadingComplete]);

  return <div style={{ display: 'none' }}></div>;
};

export default PreloadImages;
