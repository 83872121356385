import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import VideoPlayer from './components/VideoPlayer';
import Modal from './components/Modal';
import News from './components/News';
import LoadingScreen from './components/LoadingScreen';
import PreloadImages from './components/PreloadImages';

function App() {
  const [feedItems, setFeedItems] = useState({});
  const [newsItems, setNewsItems] = useState({});
  const currentCategoryIndex = useRef(0);
  const currentNewsIndex = useRef(0);
  const [selectedVideo, setSelectedVideo] = useState('jfKfPfyJRdk'); // デフォルトのYouTube動画ID
  const [inputUrl, setInputUrl] = useState(''); // 入力されたURL
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [modalRadioData, setModalRadioData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const newsContainerRef = useRef(null);
  const root = useRef(null);
  const newsLength = 10;
  const maskWidth = 260;
  const [loading, setLoading] = useState(true);
  const [bgTop, setBgTop] = useState(0);
  const [bgRight, setBgRight] = useState(0);


  const radioData = [
    { value: 'jfKfPfyJRdk', label: 'lofi hip hop radio - beats to relax/study to' },
    { value: '28KRPhVzCus', label: 'lofi hip hop radio - beats to sleep/chill to' },
    { value: 'TtkFsfOP9QI', label: 'peaceful piano radio - music to focus/study to' },
    { value: 'custom', label: 'YouTube動画のURLを入力する' }
  ];

  const handleVideoSubmit = () => {
    const videoId = extractVideoIdFromUrl(inputUrl);
    if (videoId) {
      setSelectedVideo(videoId);

      if (modalRadioData === 'custom') {
        localStorage.setItem('selectedRadio', 'custom');
        localStorage.setItem('customUrl', inputUrl);
      }
    } else {
      alert('無効なYouTube URLです。');
    }
  };

  const extractVideoIdFromUrl = (url) => {
    const match = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([^\s&]+)/);
    return match ? match[1] : null;
  };

  const fetchFeed = async (url, label) => {
    try {
      const response = await fetch(url);
      const data = await response.text();
      const parser = new window.DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');
      const items = xmlDoc.getElementsByTagName('item');

      const feedData = Array.from(items)
        .slice(0, newsLength)
        .map((item) => ({
          title: item.getElementsByTagName('title')[0].textContent,
          link: item.getElementsByTagName('link')[0].textContent,
          description: item.getElementsByTagName('description')[0].textContent,
        }));

      setFeedItems((prevFeedItems) => ({
        ...prevFeedItems,
        [label]: feedData,
      }));
    } catch (error) {
      console.error('Error fetching RSS feed:', error);
    }
  };

  const newsData = [
    { value: "https://www.nhk.or.jp/rss/news/cat1.xml", label: "社会" },
    { value: "https://www.nhk.or.jp/rss/news/cat3.xml", label: "科学・医療" },
    { value: "https://www.nhk.or.jp/rss/news/cat4.xml", label: "政治" },
    { value: "https://www.nhk.or.jp/rss/news/cat5.xml", label: "経済" },
    { value: "https://www.nhk.or.jp/rss/news/cat6.xml", label: "国際" },
    { value: "https://www.nhk.or.jp/rss/news/cat7.xml", label: "スポーツ" },
    { value: "https://www.nhk.or.jp/rss/news/cat2.xml", label: "文化・エンタメ" }
  ];

  useEffect(() => {
    newsData.map((data) => fetchFeed(data.value, data.label));
    const newsContainer = newsContainerRef.current;

    if (newsContainer) {
      // news-maskの右端からスタート
      newsContainer.style.transform = `translateX(${maskWidth}px)`;

      setTimeout(() => {
        animateNewsContainer();
      }, 2000);
    }

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

   useEffect(() => {
    const newsContainer = newsContainerRef.current;
    if (!newsContainer) return;

    if (!root.current) {
      root.current = createRoot(newsContainer); // ルートを一度だけ作成
    }

    const categoryArray = newsData.map((data) => data.label);
    const currentCategory = categoryArray[currentCategoryIndex.current];
    setNewsItems(feedItems[currentCategory]);

    if (newsItems && newsItems.length > 0) {
      const currentNews = newsItems[currentNewsIndex.current];
      if (currentNews) {
        // 既存のルートを更新
        root.current.render(
          <div key={currentCategory}>
            <News {...currentNews} />
          </div>
        );
      }
    }
  }, [feedItems, newsItems]);


  const animationStep = .225; // フレームごとのステップ（ピクセル）
  let animationInterval; // animationInterval を定義

  const animateNewsContainer = () => {
    const newsContainer = newsContainerRef.current;
    let isAnimating = false;
    let currentPositionX = maskWidth; // news-maskの右端からスタート
    let currentPositionY = 0;

    const animate = () => {
      if (isAnimating) return;
      isAnimating = true;

      animationInterval = setInterval(() => {
        currentPositionX -= animationStep;
        currentPositionY += animationStep / 7.25;

        newsContainer.style.transform = `translateX(${currentPositionX}px) translateY(${currentPositionY}px)`;

        if (currentPositionX <= -newsContainer.offsetWidth - 10) {

          clearInterval(animationInterval); // アニメーション停止
          isAnimating = false; // isAnimating をリセット
          currentPositionX = maskWidth; // news-maskの右端に戻す
          currentPositionY = 0;
          newsContainer.style.transform = `translateX(${currentPositionX}px) translateY(${currentPositionY}px)`;
          showNextNews(); // 新しいニュースを表示
          setTimeout(() => {
            animate(); // 3秒後に再スタート
          }, 3000);
        }
      }, animationStep);
    };

    // 初回のアニメーションを開始
    animate();
  };

  const showNextNews = () => {
    if (currentCategoryIndex.current === newsData.length - 1) {
      currentCategoryIndex.current = 0;
    } else {
      currentCategoryIndex.current += 1;
    }

    if (currentNewsIndex.current === newsLength - 1) {
      currentNewsIndex.current = 0;
    } else {
      currentNewsIndex.current += 1;
    }

    if (currentCategoryIndex.current === 0 && currentNewsIndex.current === 0) {
      newsData.map((data) => fetchFeed(data.value, data.label));
    }
    const categoryArray = newsData.map((data) => data.label);
    const currentCategory = categoryArray[currentCategoryIndex.current];
    setNewsItems(feedItems[currentCategory]);
  }

  const handleToggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleToggleInfoModal = () => {
    setShowInfoModal((prevShowInfoModal) => !prevShowInfoModal);
  };

  const handlelRadioChange = (event) => {
    const value = event.target.value;
      setModalRadioData(value);

    // ラジオボタンで"カスタム"が選択されたらカスタムURL入力欄を表示する
    if (value === 'custom') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
      setSelectedVideo(value);

      // ラジオボタンで選択した情報をローカルストレージに保存
      localStorage.setItem('selectedRadio', value);

      // カスタムURLの保存を削除
      localStorage.removeItem('customUrl');
    }
  };

  useEffect(() => {
    const selectedRadioValue = localStorage.getItem('selectedRadio');
    if (selectedRadioValue) {
      setModalRadioData(selectedRadioValue);
      setSelectedVideo(selectedRadioValue);

      setShowCustomInput(selectedRadioValue === 'custom' || !!localStorage.getItem('customUrl'));
    } else {
      setModalRadioData(radioData[0].value);
    }

    const customUrl = localStorage.getItem('customUrl');
    if (customUrl) {
      setInputUrl(customUrl);
    }
  }, []);

  const handleLoadingComplete = () => {
    adjustBgTop();
    adjustBgRight();
    setLoading(false);
  };

  const adjustBgTop = () => {
    const bgElement = document.querySelector('.bg');
    if (bgElement) {
      const screenHeight = window.innerHeight;
      const bgHeight = bgElement.offsetHeight;
      setBgTop(`${screenHeight * 0.75 - bgHeight}px`);
    }
  };

  const adjustBgRight = () => {
    const bgElement = document.querySelector('.bg');
    const screenWidth = window.innerWidth;
    const bgWidth = bgElement.offsetWidth;
    if (bgElement && screenWidth < bgWidth) {
      setBgRight(`${(bgWidth - screenWidth) / 2 * -0.64}px`);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      adjustBgTop();
      adjustBgRight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container">
      <LoadingScreen loading={loading} />
      <PreloadImages onLoadingComplete={handleLoadingComplete} />
      <div className="bg" style={{ top: bgTop, right: bgRight }}>
        <div className="layer"></div>
        <h1 className="app-title">日々ので<span>き</span>ごと<button className="icon-info" onClick={handleToggleInfoModal}></button></h1>
        <VideoPlayer selectedVideo={selectedVideo === 'custom' ? extractVideoIdFromUrl(inputUrl) : selectedVideo} />
        <button onClick={handleToggleModal} className="button-settings"></button>

        {/* 設定モーダル */}
        <div className={`modal ${showModal ? 'show' : ''}`}>
          <Modal>
            {radioData.map((radio) => (
              <label key={radio.value} className="modal-radio-label">
                <input
                  type="radio"
                  name="modalVideoRadio"
                  value={radio.value}
                  checked={modalRadioData === radio.value}
                  onChange={handlelRadioChange}
                  className="modal-radio"
                />
                {radio.label}
                <br />
              </label>
            ))}

            {showCustomInput && (
              <div className="form-container">
                <input
                  type="text"
                  value={inputUrl}
                  onChange={(e) => setInputUrl(e.target.value)}
                  placeholder="https://www.youtube.com/watch?v=example"
                  className="youtube-url-input"
                />
                <button onClick={handleVideoSubmit} className="button-submit">決定</button>
              </div>
            )}
            <button onClick={closeModal} className="button-close"></button>
          </Modal>
        </div>
        {/* infoモーダル */}
        <div className={`info-modal ${showInfoModal ? 'show' : ''}`} onClick={handleToggleInfoModal}>
          <Modal>
            <h2>このサイトについて</h2>
            <p>このサイトでは、NHKニュースのRSSを取得し表示しています。</p>
            <p>ニュースのテキストをクリックすると、詳細な記事のページに移動します。</p>
            <p>BGMのYouTube動画は、右下の設定ボタンから変更できます。</p>
          </Modal>
        </div>
        {/* ニュースの表示 */}
        <div className="news-mask">
          <div ref={newsContainerRef} id="news-container" className="news-container"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
