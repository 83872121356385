const News = ({ title, link, description }) => {
  return (
    <div>
      <p className="news-description">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {description}
        </a>
      </p>

    </div>
  );
};

export default News;
