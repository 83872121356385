import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ selectedVideo }) => {
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [originalVolume, setOriginalVolume] = useState(null);
  const [isVideoReady, setIsVideoReady] = useState(false);

  useEffect(() => {
    // Retrieve volume from localStorage on component mount
    const storedVolume = localStorage.getItem('videoPlayerVolume');
    if (storedVolume !== null) {
      setVolume(Number(storedVolume));
    }
  }, []);

  useEffect(() => {
    // Update localStorage whenever volume changes
    localStorage.setItem('videoPlayerVolume', volume.toString());
  }, [volume]);

  const onReady = (event) => {
    const newPlayer = event.target;
    if (newPlayer) {
      setPlayer(newPlayer);
      if (volume !== null) {
        newPlayer.setVolume(volume);
      }
      setIsVideoReady(true);
    }
  };

  const toggleVideo = () => {
    if (isPlaying) {
      player.pauseVideo();
    } else {
      player.playVideo();
    }
    setIsPlaying(!isPlaying);
  };

  const onVolumeChange = (event) => {
    const newVolume = Number(event.target.value);
    setVolume(newVolume);
    player.setVolume(newVolume);
  };

  let volumeClassName = '';

  if (volume < 1) {
    volumeClassName = 'icon-volume -mute';
  } else if (volume >= 1 && volume <= 33) {
    volumeClassName = 'icon-volume -low';
  } else if (volume >= 34 && volume <= 66) {
    volumeClassName = 'icon-volume -mid';
  } else if (volume >= 67 && volume <= 100) {
    volumeClassName = 'icon-volume -high';
  }

  const onClickMute = () => {
    if (volume !== 0) {
      // If not already muted, save the current volume
      setOriginalVolume(volume);
      setVolume(0);
      player.setVolume(0);
    } else {
      // If already muted, restore the original volume
      setVolume(originalVolume || 100);
      player.setVolume(originalVolume || 100);
      setOriginalVolume(null);
    }
  };

  const onStateChange = (event) => {
    const playerState = event.data;
    if (playerState === window.YT.PlayerState.PLAYING) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  return (
    <>
      <div className={`video-container ${isVideoReady ? 'show' : ''}`}>
        <YouTube
          videoId={selectedVideo}
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              modestbranding: 1,
              rel: 0,
            },
          }}
          onReady={onReady}
          onStateChange={onStateChange}
        />
      </div>
      <div className="video-controller">
        <button onClick={toggleVideo} className={isPlaying ? 'button-pause' : 'button-play'}></button>
        <button className={volumeClassName} onClick={onClickMute}></button>
        <input
          type="range"
          min="0"
          max="100"
          value={volume}
          onChange={onVolumeChange}
        />
      </div>
    </>
  );
};

export default VideoPlayer;
