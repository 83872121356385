import React from 'react';

const LoadingScreen = ({ loading }) => {

  return (
    <div className={`loading-screen ${loading ? '' : 'fade-out'}`}>
      {/* Your loading screen content */}
    </div>
  );
};

export default LoadingScreen;
